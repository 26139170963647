import store from '@/store'
import moment from 'moment'

export const useBankStatements = () => {

  const submitValidatedBankStatement = (bankStatement) => {
    return new Promise((resolve, reject) => {
      if ('id' in bankStatement && bankStatement.id != null) {
        updateBankStatement(bankStatement)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewBankStatement(bankStatement)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewBankStatement = (bankStatement) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankStatement/addBankStatement', bankStatement)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateBankStatement = (bankStatement) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankStatement/updateBankStatement', bankStatement)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeBankStatements = (bankStatementDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [bankStatementDocument, 'bankStatement'])
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadBankStatement = (bankStatement) => {
    let link = document.createElement('a')
    link.href = bankStatement.document.url
    link.target = '_blank'
    link.download = moment(bankStatement.startDate).format('L') + ' - ' + moment(bankStatement.endDate).format('L')
    link.click()
  }

  const removeBankStatement = (bankStatement) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankStatement/removeBankStatement', bankStatement)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedBankStatement,
    recognizeBankStatements,
    downloadBankStatement,
    removeBankStatement,
  }
}