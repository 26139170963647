import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '../../../utils/filter'
import { useBankAccounts } from './useBankAccounts'

import ModalBankAccount from '../../../components/prompt/BankAccount'
import i18n from '@/libs/i18n'
import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'
import CardBankAccount from '../../../components/card/BankAccount'
import CardNew from '../../../components/card/New'

export default {
  components: {
    ModalBankAccount,
    ButtonEdit,
    ButtonDelete,
    CardBankAccount,
    CardNew
  },
  props: {},
  setup () {
    const {
      submitValidatedBankAccount,
      removeBankAccount
    } = useBankAccounts()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentBankAccount = ref(null)
    const bankAccountModalTitle = ref(i18n.t('NewBankAccount'))
    const bankAccountModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(bankAccountModalShow, val => {
      if (val == false) {
        resetCurrentBankAccount()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetCurrentBankAccount = () => {
      bankAccountModalTitle.value = i18n.t('NewBankAccount')
      currentBankAccount.value = JSON.parse(JSON.stringify({}))
    }

    const selectBankAccount = (bankAccount) => {
      currentBankAccount.value = JSON.parse(JSON.stringify(bankAccount))
      bankAccountModalShow.value = true
    }

    const submitValidatedBankAccountLocal = () => {
      submitValidatedBankAccount(currentBankAccount.value)
        .then(response => {
          bankAccountModalShow.value = false
        })
    }

    const removeBankAccountLocal = (bankAccount) => {
      removeBankAccount(bankAccount)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCurrentBankAccount()

    return {
      // Components
      currency,
      capitalize,

      // Data
      currentBankAccount,
      bankAccountModalTitle,
      bankAccountModalShow,

      // Computed

      // Methods
      selectBankAccount,
      submitValidatedBankAccountLocal,
      removeBankAccountLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteBankAccountAlert (bankAccount) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theBankAccount') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeBankAccountLocal(bankAccount)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}