<template>
  <b-row>

    <!--          Account number-->
    <b-col cols="12">
      <field-input
          name="accountNumber"
          rules="required"
          :model.sync="bankAccount.accountNumber"
      />
    </b-col>

    <!--        Bank-->
    <b-col cols="12">
      <field-select
          name="bank"
          :model.sync="bankAccount.bank"
          :options="banks"
          label="title"
          reduce="value"
          :placeholder="$t('bank')"
          :is-required="true"
      />
    </b-col>

    <!--        Iban-->
    <b-col cols="12">
      <field-input
          name="iban"
          rules="required"
          :model.sync="bankAccount.iban"
      />
    </b-col>

    <!--        Bic-->
    <b-col cols="12">
      <field-input
          name="bic"
          rules="required"
          :model.sync="bankAccount.bic"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldInput from '../input/Input'
import FieldSelect from '../input/Select'
import store from '@/store'

export default {
  components: {
    FieldInput,
    FieldSelect
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const banks = computed(() => {
      return store.getters['bankAccount/getBanks'];
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      banks,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>