import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '../../../utils/filter'
import { useBankStatements } from './useBankStatements'
import { useBankOperations } from './useBankOperations'

import SearchFilter from '../../../components/searchFilter/SearchFilter'
import CardBankOperation from '@/components/card/BankOperation'
import ModalUpload from '@/components/prompt/Upload'
import ModalBankStatement from '@/components/prompt/BankStatement'
import ModalBankOperation from '@/components/prompt/Payment'
import useAPI from '../../../utils/useAPI'
import i18n from '@/libs/i18n'
import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'
import store from '@/store'
import { isObject } from '../../../utils/utils'

export default {
  components: {
    SearchFilter,
    CardBankOperation,
    ModalUpload,
    ModalBankStatement,
    ModalBankOperation,
    ButtonEdit,
    ButtonDelete
  },
  props: {},
  setup () {
    const {
      submitValidatedBankStatement,
      recognizeBankStatements,
      downloadBankStatement
    } = useBankStatements()

    const { submitValidatedBankOperation, removeBankOperation } = useBankOperations()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newBankStatementFile = ref(null)
    const bankBankStatementByUploadModalShow = ref(false)

    const currentBankStatement = ref(null)
    const bankStatementModalTitle = ref(i18n.t('NewBankStatement'))
    const bankStatementModalShow = ref(false)

    const currentBankOperation = ref(null)
    const bankOperationModalTitle = ref(i18n.t('NewBankOperation'))
    const bankOperationModalShow = ref(false)

    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'operationDate', label: i18n.tc('date'), sortable: true },
      { key: 'bank', label: i18n.t('bank'), sortable: true },
      { key: 'wording', label: i18n.t('wording'), sortable: true },
      { key: 'bankStatement', label: i18n.t('bankStatement'), sortable: false },
      { key: 'status', label: i18n.t('status'), sortable: true },
      { key: 'amount', label: i18n.t('amount'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccounts } = useAPI()

    const bankOperations = computed(() => {
      let output = []
      bankAccounts.value.forEach(ba => {
        ba.bankOperations.forEach(bo => {
          let boLocal = JSON.parse(JSON.stringify(bo))
          boLocal.bankAccount = {
            id: ba.id,
            _display: ba._display
          }
          output.push(boLocal)
        })
      })

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newBankStatementFile, (val) => {
      if (val != null) {
        recognizeBankStatements(val)
          .then(response => {
            bankBankStatementByUploadModalShow.value = false
            currentBankStatement.value = response.data
            newBankStatementFile.value = null
            bankStatementModalShow.value = true

            let i = 0
            currentBankStatement.value.bankOperations.forEach(bo => {
              bo._uuid = i
              i++
            })
          })
      }

    })

    watch(bankOperations, (bos) => {
      if (bos.length && ('_filters' in bos[0])) {
        bos[0]._filters.forEach(filter => {
          if (!additionalSortOptions.value.includes(filter)) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchBankAccounts,
      fetchIncomingInvoices,
      fetchOutgoingInvoices,
      fetchPaymentMethods
    } = useAPI()

    const resetCurrentBankStatement = () => {
      currentBankStatement.value = JSON.parse(JSON.stringify(null))
    }

    const resetCurrentBankOeration = () => {
      bankOperationModalTitle.value = i18n.t('NewBankOperation')
      currentBankOperation.value = JSON.parse(JSON.stringify({
        debit: 0
      }))
    }

    const selectBankOperation = (selectedBankOperation) => {
      bankOperationModalTitle.value = i18n.t('EditBankOperation')
      currentBankOperation.value = JSON.parse(JSON.stringify(selectedBankOperation))
      bankOperationModalShow.value = true
    }

    const submitValidatedBankStatementLocal = () => {
      performSubmitBankStatement(currentBankStatement.value)
      bankStatementModalShow.value = false
    }

    const performSubmitBankStatement = (bankStatement) => {
      let bankOperations = JSON.parse(JSON.stringify(bankStatement.bankOperations))

      delete bankStatement.bankOperations
      submitValidatedBankStatement(bankStatement)
        .then(async (response) => {
          console.log(response.data)

          for (let i = 0; i < bankOperations.length; i++) {
            bankOperations[i].bankStatement = response.data.id

            await new Promise(resolve => {
              submitValidatedBankOperation(bankOperations[i])
                .then(() => {
                  if (i == (bankOperations.length - 1)) {
                    resetCurrentBankStatement()
                  }
                  resolve()
                })
            })
          }

        })
    }

    const submitValidatedBankOperationLocal = () => {
      // console.log(currentBankOperation.value)
      submitValidatedBankOperation(currentBankOperation.value)
        .then(response => {
          if (currentBankOperation.value.bankAccount != null) {
            // console.log(currentBankOperation.value.bankAccount)
            //
            // if (isObject(currentBankOperation.value.bankAccount)) {
            //   let bankAccount = store.getters['bankAccount/getBankAccount'](currentBankOperation.value.bankAccount.id)
            //   bankAccount._balance = parseFloat(bankAccount._balance) + parseFloat(response.data._balance)
            // } else {
            //   let bankAccount = store.getters['bankAccount/getBankAccount'](currentBankOperation.value.bankAccount)
            //   bankAccount._balance = parseFloat(bankAccount._balance) + parseFloat(response.data._balance)
            // }

          }

          bankOperationModalShow.value = false
          resetCurrentBankOeration()
        })
    }

    const removeBankOperationLocal = (bankOperation) => {
      removeBankOperation(bankOperation)
        .then(() => {
          if (bankOperation.bankAccount != null) {
            let bankAccount = store.getters['bankAccount/getBankAccount'](bankOperation.bankAccount.id)
            bankAccount._balance = parseFloat(bankAccount._balance) - parseFloat(bankOperation._balance)
          }
        })
    }

    const downloadBankStatementLocal = (bankOperation) => {
      downloadBankStatement(bankOperation.bankStatement)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchBankAccounts()
      .then(() => {
        fetchPaymentMethods()
        fetchIncomingInvoices()
        fetchOutgoingInvoices()
      })

    resetCurrentBankStatement()
    resetCurrentBankOeration()

    return {
      // Components
      currency,
      capitalize,

      // Data
      newBankStatementFile,
      bankBankStatementByUploadModalShow,

      currentBankStatement,
      bankStatementModalTitle,
      bankStatementModalShow,

      currentBankOperation,
      bankOperationModalTitle,
      bankOperationModalShow,

      additionalSortOptions,
      tableColumns,

      // Computed
      bankAccounts,
      bankOperations,

      // Methods
      selectBankOperation,
      submitValidatedBankStatementLocal,
      submitValidatedBankOperationLocal,
      removeBankOperationLocal,
      downloadBankStatementLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteBankOperationAlert (salary) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theBankOperation') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeBankOperationLocal(salary)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}