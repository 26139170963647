<template>
  <div>
    <search-filter
        :items="bankOperations"
        keyTitleForAlphabeticalSort="wording"
        :additionalSortOptions="additionalSortOptions"
        :selectedView="'list'"
        :excludedKeys="['bankOperation']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        :allowAdd="true"
        :allowAddByUpload="true"
        @columnClick="selectBankOperation($event)"
        @addItem="bankOperationModalShow=true"
        @addItemByUpload="bankBankStatementByUploadModalShow=true"
    >
      <!--      sections top-->
      <template #sectionsTop>
        <b-row>

          <b-col
              md="3"
              v-for="bankAccount in bankAccounts"
          >
            <card-bank-account
                :bank-account="bankAccount"
                @click="selectBankAccount(bankAccount)"
                @edit="selectBankAccount(bankAccount)"
                @delete="deleteBankAccountAlert(bankAccount)"
            />
          </b-col>

          <b-col md="3">
            <b-card
                class="p-1 cursor-pointer"
                @click="bankAccountModalShow = true"
                no-body
            >
              <b-card-text style="height: 36px"
                           class="d-flex my-auto">
                <div class="d-flex justify-content-between my-auto">
                  <icon icon="plus-circle"
                        size="2x"/>
                  <div class="w-100 pl-2 my-auto">
                    {{ $t('AddBankAccount') }}
                  </div>
                </div>
              </b-card-text>

            </b-card>
          </b-col>

        </b-row>
      </template>


      <!--            Grid view-->
      <template v-slot:gridView="{item}">
        <card-bank-operation
            :bankOperation="item"
            @click="selectBankOperation(item)"
            @edit="selectBankOperation(item)"
            @download="downloadBankStatementLocal(item)"
            @delete="deleteBankOperationAlert(item)"
        />
      </template>


      <!--      List view-->

      <!--      Cell operation date-->
      <template #listView_cell_operationDate="{item}">
        {{ item.operationDate|moment('LL') }}
      </template>

      <!--      Cell bank-->
      <template #listView_cell_bank="{item}">
        {{ item.bankAccount._display }}
      </template>

      <!--      Column bank statement-->
      <template #listView_head_bankStatement="{item}">
        <icon icon="file-medical-alt"/>
      </template>
      <!--      Cell bank statement-->
      <template #listView_cell_bankStatement="{item}">
        <icon
            @click.native.stop="downloadBankStatementLocal(item)"
            v-if="item.bankStatement != null"
            icon="file-medical-alt"
        />
      </template>

      <!--      Cell status-->
      <template #listView_cell_status="{item}">
        <b-badge
            pill
            :variant="item._isAttached?'success':'secondary'"
        >
          <small v-if="item._isAttached">{{ $t('attached') }}</small>
          <small v-else>{{ $t('notAttached') }}</small>
        </b-badge>
      </template>

      <!--      Cell amount-->
      <template #listView_cell_amount="{item}">
        <span
            :class="item._balance>=0?'text-success':'text-danger'"
        >
          {{ currency(item._balance) }}
        </span>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit-->
          <button-edit
              @click.native="selectBankOperation(item)"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-delete
              @click.native.stop="deleteBankOperationAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>

    </search-filter>

    <!-- modal bank statements by upload-->
    <modal-upload
        :singleFile.sync="newBankStatementFile"
        :documentType="'bankStatement'"
        :title="capitalize($t('bankStatement'))"
        :isOpen.sync="bankBankStatementByUploadModalShow"
    />

    <!-- modal bank statements -->
    <modal-bank-statement
        :bankStatement.sync="currentBankStatement"
        :title="bankStatementModalTitle"
        :isOpen.sync="bankStatementModalShow"
        @submitValidated="submitValidatedBankStatementLocal"
    />

    <!-- modal bank operation -->
    <modal-bank-operation
        :payment.sync="currentBankOperation"
        :title="bankOperationModalTitle"
        :isOpen.sync="bankOperationModalShow"
        :showBankAffiliation="true"
        @submitValidatedPayment="submitValidatedBankOperationLocal"
    />

    <!-- modal bank operation -->
    <modal-bank-account
        :bank-account.sync="currentBankAccount"
        :title="bankAccountModalTitle"
        :isOpen.sync="bankAccountModalShow"
        @submitValidated="submitValidatedBankAccountLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import mixinBankingFlows from './mixinBankingFlows'
import mixinBankAccount from './mixinBankAccount'

export default {
  components: {},
  mixins: [mixinBankingFlows, mixinBankAccount],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>