import store from '@/store'

export const useBankAccounts = () => {

  const submitValidatedBankAccount = (bankAccount) => {
    return new Promise((resolve, reject) => {
      if ('id' in bankAccount && bankAccount.id != null) {
        updateBankAccount(bankAccount)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewBankAccount(bankAccount)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewBankAccount = (bankAccount) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/addBankAccount', bankAccount)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateBankAccount = (bankAccount) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/updateBankAccount', bankAccount)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeBankAccount = (bankAccount) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/removeBankAccount', bankAccount)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedBankAccount,
    removeBankAccount,
  }
}