<template>
  <b-row>
    <b-col md="4">
      <field-file
          name="document"
          :isRequired="true"
          :singleFile.sync="bankStatement.document"
          documentType="bankStatement"
      />
    </b-col>

    <b-col md="8">
      <b-row>

        <!-- Start date -->
        <b-col md="6">
          <field-date
              name="startDate"
              :isRequired="true"
              :model.sync="bankStatement.startDate"
          />
        </b-col>

        <!-- End date -->
        <b-col md="6">
          <field-date
              name="endDate"
              :isRequired="true"
              :model.sync="bankStatement.endDate"
          />
        </b-col>

        <!-- starting balance -->
        <b-col md="6">
          <field-input
              name="startingBalance"
              rules="required|decimal"
              :model.sync="bankStatement.startingBalance"
          />
        </b-col>

        <!-- ending balance -->
        <b-col md="6">
          <field-input
              name="endingBalance"
              rules="required|decimal"
              :model.sync="bankStatement.endingBalance"
          />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-alert
          :variant="isBalanceCorrect?'success':'danger'"
          show
          class="mb-0"
      >
        <div class="alert-body">
          <icon :icon="isBalanceCorrect?'check':'exclamation-triangle'"
                class="mr-50"/>
          <span v-if="isBalanceCorrect">{{ $t('The amount of the transactions correspond to the balance recorded') }}
          </span>
          <span v-else>{{ $t('The transaction amount does not match the reported balance') }}</span>
        </div>
      </b-alert>
    </b-col>

    <b-col cols="12 mt-4"
           :key="componentKey">
      <transition-group name="list-complete"
                        tag="p">

        <b-row
            v-for="(bankOperation, index) in bankStatement.bankOperations"
            :key="index"
            ref="row"
            class="list-complete-item pb-2"
        >
          <b-col cols="12">
            <bank-operation
                :bankOperation="bankOperation"
                containerSize="md"
                :index="index"
                @update="componentKey++"
                @removeBankOperation="removeBankOperation(bankOperation._uuid)"
            />
          </b-col>

        </b-row>

      </transition-group>
    </b-col>

    <b-col cols="12">
      <button-add @click="addNewBankOperation"/>
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldFile from '@/components/input/File'
import FieldDate from '@/components/input/Date'
import FieldInput from '@/components/input/Input'
import BankOperation from '@/components/form/BankOperation'
import ButtonAdd from '@/components/button/Add'

export default {
  components: {
    FieldFile,
    FieldDate,
    FieldInput,
    BankOperation,
    ButtonAdd
  },
  props: {
    bankStatement: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const measuredBalance = computed(() => {
      return parseFloat(props.bankStatement.endingBalance) - parseFloat(props.bankStatement.startingBalance)
    })

    const calculatedBalance = computed(() => {
      let output = 0

      props.bankStatement.bankOperations.forEach(bo => output += parseFloat(bo._balance))

      return output
    })

    const isBalanceCorrect = computed(() => {
      console.log(measuredBalance.value.toFixed(2))
      console.log(calculatedBalance.value.toFixed(2))


      return measuredBalance.value.toFixed(2) == calculatedBalance.value.toFixed(2)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewBankOperation = () => {
      props.bankStatement.bankOperations.push({ debit: 0 })
    }

    const removeBankOperation = (uuid) => {
      let position = props.bankStatement.bankOperations.findIndex(bo => bo._uuid == uuid)
      props.bankStatement.bankOperations.splice(position, 1)
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      isBalanceCorrect,

      // Methods
      addNewBankOperation,
      removeBankOperation,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>