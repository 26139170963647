import store from '@/store'

export const useBankOperations = () => {

  const submitValidatedBankOperation = (bankOperation) => {
    return new Promise((resolve, reject) => {
      if ('id' in bankOperation && bankOperation.id != null) {
        updateBankOperation(bankOperation)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewBankOperation(bankOperation)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewBankOperation = (bankOperation) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankOperation/addBankOperation', bankOperation)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateBankOperation = (bankOperation) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankOperation/updateBankOperation', bankOperation)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeBankOperation = (bankOperation) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankOperation/removeBankOperation', bankOperation)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedBankOperation,
    removeBankOperation,
  }
}