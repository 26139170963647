<template>

  <generic-card
      :byContent="true"
  >
    <template #content>
      <content-bank-account
          @click="$emit('click')"
          @edit="$emit('edit')"
          @delete="$emit('delete')"
          :bankAccount="bankAccount"
          :isParentMethodPriority="true"
      />
    </template>

  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericCard from './Card'
import ContentBankAccount from './components/BankAccountContent'

export default {
  components: {
    GenericCard,
    ContentBankAccount
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>